export const Z_INDICES = {
  BEHIND: -1,
  ZERO: 0,
  MENU_FLYOUT: 100,
  NAV_MENU: 200,
  HEADER: 201,
  SCROLLER_DIV: 900,
  MODALS: 9999,
  COOKIE_BANNER: 10000
}
